import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ASK_ENDPOINT } from '../../firebase';
// import { CreateUserEmailPasswordRequest, SyncDashiResponse, SyncDashiRequest , SyncStatus} from 'datayaki-api';
import { DashiDO } from '../../dal/do/Dashi';
import { secureFetch } from './middleware';

export const datayakiApi = createApi({
  reducerPath: 'datayakiApi',
  baseQuery: fetchBaseQuery({ baseUrl: ASK_ENDPOINT, fetchFn: secureFetch }),
  endpoints: (builder) => ({
    listDashis: builder.query<DashiDO[], void>({
      query: () => 'dashis'
    }),
    getDashiById: builder.query<DashiDO, string>({
      query: (id) => `dashis/${id}`,
    }),
    createDashi: builder.mutation<DashiDO, void> ({
      query: () => 'dashis'
    }),
    // syncDashi: builder.mutation<SyncDashiResponse, SyncDashiRequest> ({
    //   queryFn: async (arg, api, opts, baseQuery) => {
    //     await baseQuery({url: '/sync', method: 'POST', body: arg})
    //     return {data: { currentSyncVersion: 1, status: SyncStatus.SUCCESS, dashi: {id: arg.id, encryptedTitle: arg.updatedMetadata, encryptedContent: arg.updatedContent}}};
    //   }
    // }),
    // createUser: builder.mutation<boolean, CreateUserRequest> ({
    //   queryFn: async (arg, api, opts, baseQuery) => {
    //     return { data: true };
    //   }
    // })
  })
})