import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FEEDBACK_ENDPOINT, auth } from "../../firebase";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { store } from '../store';
import { ModalType, uiSlice } from "./ui-slice";

export enum OnboardingState {
  NEW = 'NEW',
  STEP_0_TOS = 'STEP_0_TOS',
  STEP_1_UploadedCSV = 'STEP_1_UploadedCSV',
  STEP_2_AskedQuestion = 'STEP_2_AskedQuestion',
  STEP_3_AddedWidget = 'STEP_3_AddedWidget',
  STEP_4_ExplainFlow = 'STEP_4_ExplainFlow',
  STEP_5_GuidanceFlow = 'STEP_5_GuidanceFlow',
  STEP_6_SharingModal = 'STEP_6_SharingModal',
  ACTIVATED = 'ACTIVATED'
}

export enum FeedbackType {
  BUG = 'BUG',
  FEATURE_REQUEST = 'FEATURE_REQUEST',
  UX_SUGGESTION = 'UX_SUGGESTION',
  COMMENT = 'COMMENT'
}

interface CXState {
  onboardingState: OnboardingState,
  isPendingFeedback: boolean
}

const initialState: CXState = {
  onboardingState: OnboardingState.NEW,
  isPendingFeedback: false
};

export type FeedbackRequest = {
  type: FeedbackType,
  feedback: string
}

export type FeedbackResponse = {

}

export const sendFeedback = createAsyncThunk<
FeedbackResponse,
FeedbackRequest,
{ rejectValue: string }>(
  'cx/sendFeedback',
  async (feedback: FeedbackRequest, thunkApi) => {
    console.log(`Sending feedback: ${feedback.type} ${feedback.feedback}`);
    try {
      const authToken = await auth.currentUser?.getIdToken();
      const response =
        await axios.post(
          FEEDBACK_ENDPOINT,
          feedback,
          {
            headers: {
              Authorization: 'Bearer '+authToken
            }
          });
          store.dispatch(uiSlice.actions.closeModal(ModalType.FEEDBACK));
      return response.data;
    } catch(error) {
      store.dispatch(uiSlice.actions.closeModal(ModalType.FEEDBACK));
      return thunkApi.rejectWithValue((error as Error).message);
    }
  }
);


export const cxSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setOnboardingState: (state, action: PayloadAction<OnboardingState>) => {
      state.onboardingState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendFeedback.pending, (state) => {
      state.isPendingFeedback = true;
    });
    builder.addCase(sendFeedback.fulfilled, (state) => {
      state.isPendingFeedback = false;
      enqueueSnackbar("Thanks for submitting feedback!", { variant: 'success', preventDuplicate: true });
    });
    builder.addCase(sendFeedback.rejected, (state) => {
      state.isPendingFeedback = false;
      enqueueSnackbar("Failed to send feedback!", { variant: 'error', preventDuplicate: true });
    });
  }
});