import { styled } from "@mui/material/styles";
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import React from 'react';
import './dialog.css';

const StyledDialog = styled(Dialog)(
  {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(255,255,255,0.2)',
    padding: '20px'
  }
)

export interface YakiDialogProps {
  open: boolean;
  title?: string;
  onClose: () => void;
  showLogo?: boolean;
  children?: React.ReactNode;

}

export function YakiDialog(props: YakiDialogProps) {
  return (
    <StyledDialog onClose={props.onClose} open={props.open}>
      {props.showLogo && <div className="dialog-logo"/>}
      {props.title && (
        <div className="dialog-title">
          <Typography variant="h6" component="h2">
            {props.title}
          </Typography>
        </div>
      )}

      {props.children}
    </StyledDialog>
  );
}