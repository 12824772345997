import { ForwardedRef, KeyboardEventHandler, forwardRef, useEffect, useRef, useState } from "react";
import { AskMessage as AskMessageDO, Message, SystemMessage as SystemMessageDO, UserMessage as UserMessageDO, askQuestion, askSlice, getWidgetFunction } from "../../app/controllers/ask-slice"
import { store } from "../../app/store"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import './ask.css';
import { TransitionGroup } from "react-transition-group";
import { Chip, Collapse, Grow, InputAdornment, TextField, Typography } from "@mui/material";
import { ErrorBoundary } from "../utils";
import styled, { keyframes } from "styled-components";
import { AddCircleOutlined, QuestionMarkRounded, RefreshRounded, Send } from "@mui/icons-material";
import { dashiSlice } from "../../app/controllers/dashi-slice";
import { DatayakiWidget } from "../../dal/do/Widget";
import { DatayakiWidgetEl } from "../dashi/widget/Widget";
import * as React from 'react';

export const Ask = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div ref={ref} className="ask_container">
      <AskMessages/>
      <AskQuery/>
    </div>
  )
});

function AskMessages() {
  const askHistory = useAppSelector(state => state.ask.history);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const askEnabled = useAppSelector(state => state.ask.enabled);
  const askDisplay = useAppSelector(state => state.ask.display);
  const askError = useAppSelector(state => state.ask.error);


  useEffect(() => {
      setTimeout(() => { messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' }) }, 500);
  },[askHistory, askEnabled, askDisplay, messagesEndRef]);
  return (
    <div className="ask_messages_container">
      <TransitionGroup>
        { askEnabled && askDisplay && askHistory.map( m => {
            return (
            <Collapse key={m.id} >
              { 
                m.sender === 'datayaki' ?
                  <ErrorBoundary fallback={(_error) => {
                    return (<SystemMessage message={{sender: 'system', id: 'error', content: '🤖 Beep boop bop! Does not compute!'}}/>)
                  }}>
                    <AskMessage message={m as AskMessageDO}/>
                  </ErrorBoundary>
                : (m.sender === 'system') ?
                  <SystemMessage message={m as SystemMessageDO}/>
                :
                  <UserMessage message={m as UserMessageDO}/>
              }
            </Collapse> 
            );
          })
        }
        { askError !== null && <SystemMessage message={{sender: 'system', content: askError, id: 'error'}}/>}
      </TransitionGroup>
      <div ref={messagesEndRef}/>
    </div>
  );
}

function AskQuery() {

  const queryRef = useRef<HTMLInputElement>(null);
  const askEnabled = useAppSelector(state => state.ask.enabled);
  const askDisplay = useAppSelector(state => state.ask.display);
  const askPending = useAppSelector(state => state.ask.status === 'pending');
  const datasets = useAppSelector(state => state.dashi.datasets);
  const datasetCount = Object.keys(datasets).length;
  const messages = useAppSelector(state => state.ask.history);

  const dispatchQuestion = () => {
    if (queryRef.current && queryRef.current.value.trim() !== '') {
      store.dispatch(askQuestion({ content: queryRef.current.value.trim() }));
      queryRef.current.value = '';
      queryRef.current.focus();
    }
  }

  const onKeyDownHandler : KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'ArrowUp') {
      event.stopPropagation();
      console.log('UP: '+queryRef.current?.value)
      const prevMessages = messages.filter((m: Message) => m.sender === 'user');
      if (prevMessages.length > 0 && queryRef.current) {
        queryRef.current.value = prevMessages[prevMessages.length -1].content as string;
      }
    }
    if (event.key === 'Enter' && !event.shiftKey) {
      dispatchQuestion();
    }
  }

  useEffect(() => {
    if (queryRef.current && !askPending) {
      queryRef.current.focus();
    }
  }, [askPending, askDisplay]);

  return (
    <div className="ask_query_container">
      <div className="ask_query_icon" onClick={()=>store.dispatch(askSlice.actions.toggleView())}>{askPending && <span>🤖</span>}</div>
      {
        askDisplay && askEnabled &&
        <Grow in={true}>
          <div className="ask_query_input">
            {askPending && <PendingMessage/>}
            {!askPending && <TextField
            variant = 'filled'
            label={(datasetCount === 0) ? "Please add datasets first." : "Ask Datayaki"}
            placeholder="What can I ask about the dataset?"
            multiline
            maxRows = {2}
            fullWidth
            sx = {{ backgroundColor: '#fff' }}
            disabled={askPending || datasetCount === 0}
            inputRef={queryRef}
            onKeyDown={onKeyDownHandler}
            InputProps={{
              // startAdornment: (datasetCount === 0) ? <InputAdornment position="start"><WarningOutlined/></InputAdornment> : undefined,
              endAdornment: (datasetCount > 0) ? <InputAdornment position="end" onClick={dispatchQuestion}><Typography  sx={{fontSize: 'xx-small'}} className="ask_send_button"><Send fontSize="large"/></Typography></InputAdornment> : undefined
            }}
            />}
          </div>
        </Grow>
      }
    </div>
  )
}

const pendingAnimation = keyframes`
0% { opacity: 0; transform: translateY(-10px) rotateZ(90deg); filter: blur(2px);}
10% { opacity: 1; transform: translateY(0px) rotateZ(0deg); filter: blur(0px);}
90% { opacity: 1; transform: translateY(0px) rotateZ(0deg); filter: blur(0px);}
0% { opacity: 0; transform: translateY(10px) rotateZ(-90deg); filter: blur(2px);}
`

const PendingWrapper = styled.span`
  display: inline-block;

  span {
    opacity: 0;
    animation-name: ${pendingAnimation};
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
`

function PendingMessage() {
  const messages = [`Catbot.. 🙀 er! Chatbot is hard at work`, `This is complicated stuff!`, `A-I Schmay-I!`,`HAL 9000-n-late!`];
  // TODO: @thekumar - Code in cat gifs.

  return <PendingWrapper>{messages[parseInt(''+Math.random()*(messages.length))].split("").map((c, i)=> (<span key={c+i} style={{animationDelay: (0.1*i)+'s'}}>{c}</span>))}</PendingWrapper>
}

function AskMessage(props: {message: AskMessageDO}) {
  const widgetFn = getWidgetFunction(props.message.id);

  return (
    <div className="ask_message_datayaki">
      {
        props.message.content.type === 'function' ? (
          <>
          <DatayakiWidgetEl title={props.message.content.title} chartType={props.message.content.chart.type} widgetFn={widgetFn!} size={[1,1]}/>
          <AddWidgetChip message={props.message}/>
          {/* <ExplainChip message={props.message}/>
          <RetryChip/> */}
          </>
        ) : (
          <ErrorBoundary>
            <div className="ask_message_datayaki_content">{props.message.content.result}</div>
          </ErrorBoundary>
        )
      }
    </div>
  )
}

function AddWidgetChip(props: {message: AskMessageDO}) {
  const [ hovered, setHovered ] = useState(false);
  const dispatch = useAppDispatch();
  const userID = useAppSelector(state => state.auth.userID);

  return (
    <Chip
      size="small"
      sx={{
        ":hover":{
          backgroundColor: 'rgba(255,255,255,0.5)'
        },
        backgroundColor: 'rgba(255,255,255,0.25)',
        marginTop: '5px',
        transition: 'all 300ms ease-in'
      }}
      icon={
        <AddCircleOutlined color="action"/>
      }
      onClick={()=>{
        dispatch(dashiSlice.actions.addWidget({widget: (new DatayakiWidget(crypto.randomUUID(), 'dashi', userID!, props.message)).toWidgetDO()}))
      }}
      onMouseEnter={(e) => {setHovered(true)}}
      onMouseLeave={(e) => {setHovered(false)}}
      label={hovered?'Add Widget':undefined}
      className = "ask_add_widget_button"
    />)
}

export function ExplainChip(props: {message: AskMessageDO}) {
  const [ hovered, setHovered ] = useState(false);

  return (
    <Chip
      size="small"
      sx={{
        ":hover":{
          backgroundColor: 'rgba(255,255,255,0.5)'
        },
        backgroundColor: 'rgba(255,255,255,0.25)',
        marginTop: '5px',
        marginLeft: '5px',
        transition: 'all 300ms ease-in'
      }}
      icon={
        <QuestionMarkRounded color="action"/>
      }
      onClick={()=>{}}
      onMouseEnter={(e) => {setHovered(true)}}
      onMouseLeave={(e) => {setHovered(false)}}
      label={hovered?'Explain':undefined}
    />)
}


export function RetryChip() {
  const [ hovered, setHovered ] = useState(false);

  return (
    <Chip
      size="small"
      sx={{
        ":hover":{
          backgroundColor: 'rgba(255,255,255,0.5)'
        },
        backgroundColor: 'rgba(255,255,255,0.25)',
        marginTop: '5px',
        marginLeft: '5px',
        transition: 'all 300ms ease-in'
      }}
      icon={
        <RefreshRounded color="action"/>
      }
      onClick={()=>{}}
      onMouseEnter={(e) => {setHovered(true)}}
      onMouseLeave={(e) => {setHovered(false)}}
      label={hovered?'Try Again':undefined}
    />)
}

function UserMessage(props: {message: UserMessageDO}) {
  return (
    <div className="ask_message_user">
      <div>{props.message.content}</div>
    </div>
  )
}

function SystemMessage(props: {message: SystemMessageDO}) {
  return (
    <div className="ask_message_system">
      <div>{props.message.content}</div>
    </div>
  )
}