import { Outlet, RouteObject, useParams } from "react-router-dom";
import * as React from 'react';

export const databaseRoutes: RouteObject[] = [
    { path: '', element: <DatasetList/>},
    { path: 'new', element: <Dataset />},
    { path: ':databaseSlug', element: <Dataset />},
  ];

export function DatasetLayout() {
  return (
    <div id='dataset-layout'><Outlet/></div>
  )
}

function DatasetList() {
  return (
    <div id='dataset-list'>Dataset List</div>
  )
} 
function Dataset() {
  const { datasetSlug } = useParams();
  return (<div>Dataset: {datasetSlug}</div>);
}
