import { RootState } from './../store';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { auth } from '../../firebase';
import { store } from '../store';
import { FirebaseError } from 'firebase/app';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut, UserCredential } from 'firebase/auth';

interface AuthState {
  isLoggedIn: boolean,
  userID: string | null,
  email: string | null,
  error: string | null,
  status: 'idle' | 'pending'
}

export interface LoginRequest {
  email: string,
  password: string
}

export interface SignupRequest {
  email: string,
  password: string,
  profilePic: string
}

const initialState: AuthState = {
  isLoggedIn: false,
  userID: null,
  email: null,
  error: null,
  status: 'idle'
};

type AuthError = {
  message: string;
};

export const login = createAsyncThunk<
UserCredential & {token: string | null},
LoginRequest,
{ rejectValue: AuthError }>(
  'auth/login',
  async (req: LoginRequest, thunkApi) => {
    console.log(`login w/ email: ${req.email} and pwd: ${req.password}`);
    try {
      const userCred = await signInWithEmailAndPassword(auth, req.email, req.password);
      return { ...userCred, token: await userCred.user.getIdToken()};
    } catch (error) {
      let message = 'Unknown error occured.'
      if (error instanceof FirebaseError) {
        message = error.message;
      }
      return thunkApi.rejectWithValue({ message });
    }
  }
)

export const logout = createAsyncThunk<
void,
void,
{ rejectValue: AuthError }>(
  'auth/logout',
  async (_, thunkApi) => {
    try {
      await signOut(auth);
    } catch (error) {
      let message = 'Unknown error occured.'
      if (error instanceof FirebaseError) {
        message = error.message;
      }
      return thunkApi.rejectWithValue({ message });
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signup: (state, action: PayloadAction<SignupRequest>) => {
      console.log(`signup with email: ${action.payload.email} pass: ${action.payload.password} and profile: ${action.payload.profilePic}`);
    },
    setAuthState: (state, action: PayloadAction<Partial<AuthState>>) => {
      if (action.payload.isLoggedIn) state.isLoggedIn = action.payload.isLoggedIn;
      if (action.payload.email) state.email = action.payload.email;
      if (action.payload.userID) state.userID = action.payload.userID;
      if (action.payload.error) state.error = action.payload.error;
      if (action.payload.status) state.status = action.payload.status;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.status = "pending";
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.error = null;
      state.isLoggedIn = true;
      state.userID = payload.user.uid;
      state.email = payload.user.email;
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      if (payload) state.error = payload.message;
    });
    builder.addCase(logout.pending, (state) => {
      state.status = "pending";
      state.error = null;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.status = "idle";
      state.isLoggedIn = false;
      state.error = null;
      state.userID = null;
      state.email = null;
    });
    builder.addCase(logout.rejected, (state, { payload }) => {
      if (payload) state.error = payload.message;
    });
  }
});

onAuthStateChanged(
  auth,
  async (user) => {
    store.dispatch(authSlice.actions.setAuthState(
      {isLoggedIn: user ? true : false, userID: user?.uid ?? null, email: user?.email ?? null, error: null, status: 'idle'}));
  }
);


export const selectAuthStatus = (state: RootState) => state.auth.status;

