import { Outlet, RouteObject, useRouteError, isRouteErrorResponse, NavLink } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { FeedbackModal } from '../../components/feedback/feedback';
import * as React from 'react';
import { DashiLayout, dashiActions, dashiRoutes } from './dashi';
import { DatasetLayout, databaseRoutes } from './database';
import { DashiIcon } from '../../components/ui/DashiIcon';
import { DatasetIcon } from '../../components/ui/DatasetIcon';
import { HomeOutlined } from '@mui/icons-material';
import './new.css';
import { DatasourceLayout, datasourceRoutes } from './datasource';

export default function AppRoutes() {
  let routes: RouteObject[] = [
    {
      path: '',
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        { index: true, element: <Home/>},
        { path: 'home', element: <Home />},
        { path: 'd', element: <DashiLayout/>, children: dashiRoutes, action: dashiActions},
        { path: 'db', element: <DatasetLayout/>, children: databaseRoutes},
        { path: 'ds', element: <DatasourceLayout/>, children: datasourceRoutes},
      ]
    },
    { path: '*', element: <NoMatch /> }
  ];

  return routes;
};

function Home() {
  return (<div>Home</div>)
}

function NoMatch() {
  return (
    <div>
      Not found
    </div>
  )
}

function AppNav() {
  return (
    <div id="app-nav">
      <h1>Datayaki</h1>
      <Search/>
      <NavLinks/>
      <NavUser/>
    </div>
  )
}

function NavLinks() {
  return (
    <nav id="nav-links">
      <ul>
        <li><NavLink to='home'><HomeOutlined/> Home</NavLink></li>
        <li><NavLink to='d'><DashiIcon/> Dashis</NavLink></li>
        <li><NavLink to='ds'><DatasetIcon/> Datasets</NavLink></li>
      </ul>
    </nav>
  );
}

function NavUser() {
  return (
    <div id="nav-user">Logged in user</div>
  )
}

function Search() {
  return (
    <div id='search'>
      <form id='search-form' role='search'>
        <input id='q' aria-label='Search Dashis and Datasets' placeholder='search' name='q'/>
        <div id='search-spinner' aria-hidden hidden/>
        <div className='search-results' aria-live='polite'/>
      </form>
    </div>
  );
}

export function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{isRouteErrorResponse(error) ? (error.statusText || error.error?.message) : 'Unknown error'}</i>
      </p>
    </div>
  );
}

const Layout = () => {
  return (
    <SnackbarProvider>
      <div id='layout'>
        <AppNav/>
        <Outlet />
        <FeedbackModal/>
      </div>
    </SnackbarProvider>
  )
}