import { AskMessage } from "../../app/controllers/ask-slice";
import { WidgetDO, WidgetOriginator, WidgetType } from 'datayaki-api';

export interface SerializableWidget{
  toWidgetDO(): WidgetDO;
}

export abstract class Widget implements SerializableWidget{
  static ORDER_COUNT = 0;
  id: string;
  dashiId: string;
  authorId: string;
  originator: WidgetOriginator;
  type: WidgetType;
  title: string;
  size: [number, number]; // cols x rows (x,y)
  order: number;
  info?: any;

  constructor(id: string, dashiId: string, authorId: string, originator: WidgetOriginator, type: WidgetType, title: string, info?: any) {
    this.id = id;
    this.dashiId = dashiId;
    this.authorId = authorId;
    this.originator = originator;
    this.title = title;
    this.type = type;
    this.size = [1,1];
    this.order = Widget.ORDER_COUNT++;
    this.info = info;
  }  
  toWidgetDO(): WidgetDO {
    return {
      id: this.id, dashiId: this.dashiId, authorId: this.authorId, originator: this.originator, title: this.title, type: this.type, size: this.size, order: this.order, info: this.info
    }
  }
}

export class EvalWidget extends Widget{
  constructor (id: string, dashiId: string, authorId: string, title: string, evalFnStr: string) {
    super(id, dashiId, authorId, 'user', 'eval', title, evalFnStr);
  }
}

export class DatayakiWidget extends Widget {
  constructor (id: string, dashiId: string, authorId: string, message: AskMessage) {
    super(id, dashiId, authorId, 'datayaki', 'eval', message.content.title, message);
  }
}
