import './App.css';
import { Outlet, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Demo } from './routes/demo';
import { Home } from './routes/home';
import { FeedbackModal } from './components/feedback/feedback';
import * as React from 'react';
import AppRoutes from './routes/new/new';

export default function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { index: true, element: <Home/>},
        { path: '/me', element: <Demo />},
        { path: '/demo', children: AppRoutes()},
      ]
    },
    { path: '*', element: <NoMatch /> }
  ]);

  return (
    <RouterProvider router={router}/>
  );
};

function NoMatch() {
  return (
    <div>
      Not found
    </div>
  )
}

const Layout = () => {
  return (
    <SnackbarProvider>
        <Outlet />
        <FeedbackModal/>
    </SnackbarProvider>
  )
}

