import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum ModalType {
  FEEDBACK,
  SHARE,
  INVITE,
  SETTINGS
}
interface UIState {
  isAppDrawerVisible: boolean,
  isUserDropdownVisible: boolean,
  modalStack: ModalType[]
}

const initialState: UIState = {
  isAppDrawerVisible: false,
  isUserDropdownVisible: false,
  modalStack: []
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showAppDrawer: (state, action) => {
      state.isAppDrawerVisible = true;
    },
    hideAppDrawer: (state, action) => {
      state.isAppDrawerVisible = false;
    },
    toggleAppDrawer: (state) => {
      state.isAppDrawerVisible = !state.isAppDrawerVisible;
    },
    showUserDropdown: (state) => {
      state.isUserDropdownVisible = true;
    },
    hideUserDropdown: (state) => {
      state.isUserDropdownVisible = false;
    },
    showModal: (state, action: PayloadAction<ModalType>) => {
      state.modalStack = [...state.modalStack.filter(modal => modal !== action.payload), action.payload];
    },
    closeModal: (state, action: PayloadAction<ModalType>) => {
      state.modalStack = state.modalStack.filter(modal => modal !== action.payload);
    },
    closeCurrentModal: (state) => {
      state.modalStack.pop();
    },
    closeAllModals: (state) => {
      state.modalStack = [];
    }
  }
});