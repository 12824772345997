import axios, { AxiosResponse } from "axios";
import { ASK_ENDPOINT } from "../firebase";

export class DatayakiClient {
  static async explain(req: {dashiId: string, messageId: string, explainMessageId: string}, authToken: string): Promise<AxiosResponse<{messageId: string, content: string}>> {
    return axios.post(
      ASK_ENDPOINT,
      req,
      { headers: { Authorization: 'Bearer ' + authToken }});
  }
}