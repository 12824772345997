import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth, GoogleAuthProvider } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { isProd, isLocalhost } from "../utils/envUtils";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = isProd() ?
{
  apiKey: "AIzaSyB8Z__JKwgnh4EMWv9xfcsmzreK4dHb1sI",
  authDomain: "datayaki.firebaseapp.com",
  projectId: "datayaki",
  storageBucket: "datayaki.appspot.com",
  messagingSenderId: "696909610325",
  appId: "1:696909610325:web:e9604a2a136228b909c3dc",
  measurementId: "G-PCKK9FH8NC",
} :
{
  apiKey: "AIzaSyCBvNT6U2_ExFnARGKS7JMo-d0jQLYsJjY",
  authDomain: "datayaki-staging.firebaseapp.com",
  projectId: "datayaki-staging",
  storageBucket: "datayaki-staging.appspot.com",
  messagingSenderId: "613375379200",
  appId: "1:613375379200:web:dff8a211c0bf673c4995e5",
  measurementId: "G-13S8EDVHPK"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);
export const googleProvider = new GoogleAuthProvider();

if (isLocalhost()) {
  console.log('LOCALHOST DETECTED!')
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
  connectFirestoreEmulator(firestore, 'http://127.0.0.1', 8080);
}

export const BASE_URL = isProd() ? "https://ask-m44lypt4ma-uc.a.run.app" : isLocalhost() ? "http://127.0.0.1:5001/datayaki-staging/us-central1/ask" : "https://ask-zs7lkvuija-uc.a.run.app";
export const ASK_ENDPOINT = BASE_URL;
export const SUGGEST_ENDPOINT = BASE_URL + '/topq';
export const FEEDBACK_ENDPOINT = BASE_URL + '/feedback';