import React from "react";
import { ErrorBoundary } from "../../utils";
import { ChartRenderer, DataframeRenderer } from "./renderers";
import { ChartType } from "../../../app/controllers/ask-slice";
import { useAppSelector } from "../../../app/hooks";
import './widget.css';
import { ErrorOutline } from "@mui/icons-material";

export interface WidgetProps {
  title: string,
  chartType: ChartType | 'N/A',
  widgetFn: ((datasets: any) => any),
  size: [number, number],
  closeFn?: () => void
}


export function DatayakiWidgetEl(props: WidgetProps) {
  try {
    const datasets = useAppSelector(state => state.dashi.datasets);
    const widgetVal = JSON.stringify(props.widgetFn(datasets));
    const valueStr = JSON.parse(widgetVal) instanceof String ? JSON.parse(widgetVal) as string : widgetVal;

    // const widgetVal = '1234';
    // const valueStr = '1234';

    return (
      <div className="widget">
        <div className="widget-header">
          <div className="widget-title">{props.title}</div>
          { props.closeFn && <div className="widget-controls"><button onClick={props.closeFn}>X</button></div> }
        </div>
        <div className="widget-result">
          <ErrorBoundary fallback={(_) => <>{valueStr}</>} silent>
            {
              props.chartType === 'N/A' ?
                <DataframeRenderer data={JSON.parse(widgetVal)} isRow={false}/> :
                <ChartRenderer type={props.chartType} data={JSON.parse(widgetVal)}/>
            }
          </ErrorBoundary>
        </div>
      </div>
    );
  } catch (e) {
    console.error(e);
    return (
      <div className="widget">
        <div className="widget-header">
          <div className="widget-title">{props.title}</div>
          { props.closeFn && <div className="widget-controls"><button onClick={props.closeFn}>X</button></div> }
        </div>
        <div className="widget-result">
          <ErrorOutline sx={{color: "red", fontSize: "xx-large"}}/>
        </div>
      </div>
    )
  }
}
