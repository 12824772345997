// import { useState } from 'react';
import { useAppSelector } from '../../app/hooks';
// import { login, selectAuthStatus } from '../../app/controllers/auth-slice';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import CircularProgress from '@mui/material/CircularProgress';
// import Button from '@mui/material/Button';
// import FormControl from '@mui/material/FormControl';
// import IconButton from '@mui/material/IconButton';
// import InputLabel from '@mui/material/InputLabel';
// import InputAdornment from '@mui/material/InputAdornment';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import PasswordIcon from '@mui/icons-material/Password';
// import { VisibilityOff, Visibility } from '@mui/icons-material';
// import { store } from '../../app/store';
import { YakiDialog } from '../Dialog';
import './login.css';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../firebase';
import { useState } from 'react';
import { Checkbox } from '@mui/material';
import * as React from 'react';

export function Login() {
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);
  const [isAcceptedTOS, setIsAcceptedTOS] = useState(false);

  return (
    <YakiDialog title="Sign into Datayaki" showLogo={true} open={!isLoggedIn} onClose={()=>{}}>
      <div className="login-container">
        <div><Checkbox checked={isAcceptedTOS} onChange={(_, checked) => setIsAcceptedTOS(checked)}/>I accept the <a href="https://bit.ly/datayaki-legal" target="datayaki-legal">Terms of Service</a></div>
        <button type="button" disabled={!isAcceptedTOS} className="login-with-google-btn" onClick={() => signInWithPopup(auth, googleProvider)}>
          Sign in with Google
        </button>
      </div>
    </YakiDialog>
  )
}