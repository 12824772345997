import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { YakiDialog } from '../Dialog';
import './feedback.css';
import { FeedbackType, sendFeedback } from '../../app/controllers/cx-slice';
import { ModalType, uiSlice } from '../../app/controllers/ui-slice';
import { useState } from 'react';
import { MenuItem, Select, TextField } from '@mui/material';
import * as React from 'react';

export function FeedbackModal() {
  const dispatch = useAppDispatch();
  const [type, setType] = useState(FeedbackType.BUG);
  const [feedback, setFeedback] = useState('');
  const modalStack = useAppSelector(state => state.ui.modalStack);
  const isPending = useAppSelector(state => state.cx.isPendingFeedback);

  const handleSend = () => {
    dispatch(sendFeedback({type, feedback}));
  }

  const handleClose = () => {
    dispatch(uiSlice.actions.closeModal(ModalType.FEEDBACK));
  }

  return (
    <YakiDialog title="Send Feedback" open={(modalStack[modalStack.length - 1] === ModalType.FEEDBACK)} onClose={handleClose}>
      <div className="feedback-container">
        <Select<FeedbackType> value={type} onChange={(event) => setType(event.target.value as FeedbackType)}>
          <MenuItem value={FeedbackType.BUG}>Bug</MenuItem>
          <MenuItem value={FeedbackType.UX_SUGGESTION}>UX suggestion</MenuItem>
          <MenuItem value={FeedbackType.FEATURE_REQUEST}>Feature request</MenuItem>
          <MenuItem value={FeedbackType.COMMENT}>Comment</MenuItem>
        </Select>
        <TextField
              variant = 'filled'
              label="Description"
              multiline
              rows={5}
              fullWidth
              sx = {{ backgroundColor: '#fff' }}
              value={feedback}
              onChange={(event) => {setFeedback(event.target.value)}}
              />        
        <button type="button" onClick={handleClose}>Cancel</button>
        <button type="button" disabled={isPending} onClick={handleSend}>Send</button>
      </div>
    </YakiDialog>
  )
}