import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './home.css';

import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import YouTube from 'react-youtube';
import { Theme } from '@mui/material/styles';
import { FacebookOutlined, MailOutlineRounded, Reddit, Twitter } from '@mui/icons-material';

import { getCalApi } from "@calcom/embed-react";

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: '120px 0px',
    textAlign: 'center',
    minHeight: '120px',
    color: 'white',
    backgroundImage: 'linear-gradient(45deg, #ff7700, #ff3300)',
    display: 'flex',
    justifyContent: 'center'
  },
  featuresGrid: {
    padding: '40px 0',
    justifyItems: 'stretch',
  },
  featureItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  featureCard: {
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    height: '100%',
  },
  videoSection: {
    padding: '40px 0',
    textAlign: 'center',
  },
  additionalSection: {
    padding: '40px 0',
    textAlign: 'center',
  },
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '40px 0',
    flexGrow: 99
  },
  appBar: {
    alignSelf: 'center',
    borderRadius: '5px',
  },
  centerAligned: {
    textAlign: 'center'
  },
  appBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    overflow: 'visible',
    position: 'fixed',
    maxWidth: '1200px', // You can change this value to match your desired width
    width: '100%',
    alignSelf: 'center',
    backdropFilter: 'blur(5px)',
    zIndex: 999,
  },
  homeContainer: {
    backgroundColor: '#eee',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  appbarCtaButton: {
    color: 'transparent', // Text color
    fontWeight: 'bold',
    backgroundColor: '#000', // Background color
    padding: '10px 20px', // Padding
    borderRadius: '5px', // Border radius
    border: 'none', // Remove border
    cursor: 'pointer', // Cursor style
    textDecoration: 'none', // Remove underline if used within <a>
    transition: 'all 0.3s', // Transition effect for hover
    display: 'inline',
    '&:hover': {
      backgroundImage: 'var(--gradient-8)',
      color: 'white'
    },
  },
  ctaButton: {
    color: 'transparent', // Text color
    fontWeight: 'bold',
    backgroundColor: '#fff', // Background color
    padding: '10px 20px', // Padding
    borderRadius: '5px', // Border radius
    border: 'none', // Remove border
    cursor: 'pointer', // Cursor style
    textDecoration: 'none', // Remove underline if used within <a>
    transition: 'all 0.3s', // Transition effect for hover
    display: 'inline',
    '&:hover': {
      backgroundImage: 'var(--gradient-8)',
      color: 'white'
    },
  },
  marqueeHeader: {
    textAlign: 'center',
    marginTop: '40px',
    marginBottom: '20px',
    fontStyle: 'italic'
  },
  marqueeContainer: {
    display: 'flex',
    justifyContent: 'center', // Center align
    position: 'relative', // Relative position for overlay
    overflow: 'hidden', // Hide overflow
    maxWidth: '1200px',
    alignSelf: 'center',
  },
  fadeOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '200px', // Width of the fade effect
    background: 'linear-gradient(to right, #eee, transparent)', // Fade from white to transparent
    zIndex: 2, // Place it above the marquee
    '&.left': {
      left: 0
    },
    '&.right': {
      right: 0,
      background: 'linear-gradient(to left, #eee, transparent)', // Right-side fade
    },
  },
  marqueeContent: {
    display: 'flex',
    overflow: 'show', // Hide overflow
    animation: `$scrolling 10s linear infinite`,
  },
  '@keyframes scrolling': {
    '0%': {
      transform: 'translateX(0%)',
    },
    '100%': {
      transform: 'translateX(-700px)',
    },
  },
  marqueeLogo: {
    margin: '0 20px',
    filter: 'drop-shadow(1px 1px 3px rgba(0,0,0,0.4))',
    width: '100px',
    height: '100px',
    objectFit: 'contain'
  }
}));

export function CalButton() {
  const classes = useStyles();
  useEffect(()=>{
    (async function () {
      const cal = await getCalApi();
      cal("ui", {"styles":{"branding":{"brandColor":"#ff7700"}},"hideEventTypeDetails":false,"layout":"month_view"});
    })();
  }, []);
  return <Button>
    <div
    data-cal-link="datayaki/customer"
    data-cal-config='{"layout":"month_view"}'
    className={classes.appbarCtaButton}>
      <span
      data-cal-link="datayaki/customer"
      data-cal-config='{"layout":"month_view"}'
      style={{backgroundImage: 'var(--gradient-25)', WebkitBackgroundClip: 'text'}}>
        Schedule a call
      </span>
    </div>
    </Button>;
};

export function Home() {
  const classes = useStyles();

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  const videoOptions = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
      playbackRate: 1.25,
    },
  };

  const features = [
    [(<>Built to Ask</>), (<>Forget learning how to write SQL queries and Excel macros. Get to your answers quickly using plain English. Let Datayaki figure it out.</>)],
    [(<>Privacy First</>), (<>Datayaki performs all analysis within the confines of your browser/backend. Our AI only relies on the shape of your data, not its contents.</>)],
    [(<>Analyze Spreadsheets</>), (<>Datayaki comes with XLS and CSV support right out of the box. You can even analyze data across multiple spreadsheets at once.</>)],
    [(<>Explainable AI<sup>*</sup></>), (<>Trust and Explainability, built right in. Datayaki can explain itself, so you don't have to. Our AI mostly gets things right. But when it doesn't, you can guide it.</>)],
    [(<>E2EE Collaboration<sup>*</sup></>), (<>Securely share your dashboards and analyses with your peers. Your data stays end-to-end encrypted and accessible only by you and your collaborators.</>)],
    [(<>No/SQL? No Problem<sup>*</sup></>), (<>Prefer to use SQL or NoSQL databases? Datayaki provides connectors for Firebase, Postgres, Supabase, Snowflake and more...</>)],
  ];

  const socialProof = [
    {logo: 'stanford.png', title: 'Stanford Medicine'},
    {logo: 'sloan.png', title: 'Memorial Sloan Kettering Cancer Center'},
    {logo: 'quarry.png', title: 'The Quarry Lane School'},
    {logo: 'google.png', title: 'Google'},
    {logo: 'amazon.png', title: 'Amazon'},
  ]

  return (
    <div className={classes.homeContainer}>
      <div className={classes.appBarContainer}>
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Toolbar>
          <img src="./datayaki-logo.png" alt='logo' style={{marginRight: '5px'}} height="50"/>
          <Typography component="div" variant="h4" color='white' sx={{flexGrow: 1, margin: '5px', textAlign: 'start', textShadow: '1px 1px 4px rgba(0,0,0,0.5)'}}>Datayaki</Typography>
          <CalButton/>
          <Button>
            <Link to='/me' className={classes.appbarCtaButton}>
                <span style={{backgroundImage: 'var(--gradient-25)', WebkitBackgroundClip: 'text'}}>Playground →</span>
            </Link>
          </Button>
        </Toolbar>
      </AppBar>
      </div>
      <div className={classes.heroContent}>
        <div style={{maxWidth: 'min(1200px, 100%)'}}>
          <Slider {...slickSettings}>
            <div>
              <Typography variant="h2" gutterBottom>
                Ask → Analyze
              </Typography>
              <Typography variant="h6">
                Datayaki is your AI data analyst. Find insights simply by asking. No SQLs necessary.
              </Typography>
              <img src="./datayaki-chat.png" alt='' style={{margin: '20px auto'}} height="240"/>
              <Button>
                <Link to='/me' className={classes.ctaButton}>
                  <span style={{backgroundImage: 'var(--gradient-10)', WebkitBackgroundClip: 'text'}}>Sign Up</span>
                </Link>
              </Button>
            </div>
            <div>
              <Typography variant="h2" gutterBottom>
                For your eyes only.
              </Typography>
              <Typography variant="h6">
                Datayaki brings the power of LLMs to your data － not the other way around. Your data stays private with you. No peeking!
              </Typography>
              <img src="./datayaki-privacy.png" alt='' style={{margin: '20px auto'}} height="240"/>
              <Button>
                <Link to='/me' className={classes.ctaButton}>
                  <span style={{backgroundImage: 'var(--gradient-10)', WebkitBackgroundClip: 'text'}}>Sign Up</span>
                </Link>
              </Button>
            </div>
            <div>
              <Typography variant="h2" gutterBottom>
                Your data, your way.
              </Typography>
              <Typography variant="h6">
                Datayaki can handle multiple data formats. From Excel spreadsheets in your desktop to SQL databases in the cloud<sup>*</sup>.
              </Typography>
              <img src="./datayaki-connectors.png" alt='' style={{margin: '20px auto'}} height="240"/>
              <Button>
                <Link to='/me' className={classes.ctaButton}>
                  <span style={{backgroundImage: 'var(--gradient-10)', WebkitBackgroundClip: 'text'}}>Sign Up</span>
                </Link>
              </Button>
            </div>
          </Slider>
        </div>
      </div>
      <Container className={classes.featuresGrid}>
        <Grid container spacing={4} >
          {features.map((feature, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} className={classes.featureItem}>
              <Card className={classes.featureCard}>
                <CardContent>
                  <Typography variant="h5">{feature[0]}</Typography>
                  <Typography variant="body1">
                    {feature[1]}
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>


      {/* Social proof marquee */}
      <div className={classes.marqueeHeader}>Trusted by academicians and professionals at</div>
      <div className={classes.marqueeContainer}>
        <div className={classes.fadeOverlay + ' left'}></div> {/* Left fade overlay */}
        <div className={classes.fadeOverlay + ' right'}></div> {/* Right fade overlay */}
        <div className={classes.marqueeContent}>
          {[1,2,3,4].flatMap(x => socialProof.map((proof, index) => (
            <img src={proof.logo} alt={proof.title} className={classes.marqueeLogo} key={x+'.'+index}/>
          )))}
        </div>
      </div>

      <div className={classes.videoSection}>
        <YouTube videoId="Pr0qolSGIAg" opts={videoOptions} />
      </div>

      <div className={classes.centerAligned} style={{margin: '20px 0px 40px 0px'}}>
        <CalButton/>
        <br/>
        <i>15 mins or less could save your team $150K or more!</i>
      </div>

      {/* <div className={classes.additionalSection}>
        <Button><a href="https://www.producthunt.com/posts/datayaki?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-datayaki" target="_blank" rel="noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=409884&theme=light" alt="Datayaki - Privacy&#0045;first&#0032;AI&#0032;data&#0032;analyst | Product Hunt" style={{width: '250px', height: '54px'}} width="250" height="54" /></a></Button>
      </div> */}
      <div className={classes.centerAligned}><sup>*</sup>Available soon.</div>
      <footer className={classes.footer}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4} sx={{textAlign: 'justify'}}>
              <Typography variant="body1" color='GrayText'><a href="mailto:hello@yaki.app"><MailOutlineRounded sx={{fontSize: 50}}/></a> <a href="https://facebook.com/datayaki"><FacebookOutlined sx={{fontSize: 50}}/></a> <a href="https://twitter.com/data_yaki"><Twitter sx={{fontSize: 50}}/></a> <a href="https://reddit.com/r/datayaki"><Reddit sx={{fontSize: 50}}/></a></Typography>
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign: 'justify'}}>
              <Typography variant="body1"><a href="https://bit.ly/datayaki-legal">Attributions</a></Typography>
              <Typography variant="body1"><a href="https://bit.ly/datayaki-legal">Privacy Policy</a></Typography>
              <Typography variant="body1"><a href="https://bit.ly/datayaki-legal">Terms of Service</a></Typography>
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign: 'justify'}}>
            <a href="https://yaki.company"><img src="/yaki-white.png" alt='logo' height="40" style={{float: "right", opacity: 0.5}}/></a>
            {/* <img src="/datayaki-outline.png" alt='logo' height="50" style={{float: "right"}}/> */}
              <Typography variant="body1">About Us</Typography>
              <Typography variant="body1">Team</Typography>
              <Typography variant="body1">&copy; 2023 Yaki, Inc.</Typography>
            </Grid>
          </Grid>
        </Container>
      </footer>
      {/* <Helmet>
      <script type="text/javascript">
        {`
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/64dd34e594cf5d49dc6ad1cb/1h801d8p5';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();
        `}
      </script>        
      </Helmet> */}
    </div>
  );
}
