import { ActionFunction, ActionFunctionArgs, Form, Link, LoaderFunction, LoaderFunctionArgs, Outlet, RouteObject, redirect, useLoaderData, useParams } from "react-router-dom";
import * as React from 'react';
import { DashiDO, DashiSectionDO } from "../../dal/do/Dashi";
import { Text } from "@automerge/automerge";

export const dashiLoader: LoaderFunction = async (args: LoaderFunctionArgs) => {
  console.log('I was called!!!');
  const dashi = await getDashi(args.params.dashiSlug as string);
  return dashi;
}

export const dashiListLoader: LoaderFunction = async () => {
  console.log('Loading the dashi list');
  const dashiList = [{name: 'ABC', id:'1234'}, {name: 'Easy as 123', id:'5678'},{name: 'Beverley Hills!', id:'90210'}];
  return dashiList;
}

export const dashiActions: ActionFunction = async (arg: ActionFunctionArgs) => {
  console.log(JSON.stringify(arg));
  return redirect('../1234');
} 

export const newDashiLoader: LoaderFunction = async () => {
  const dashi = await getDashi('new');
  return dashi;
} 

export const dashiRoutes: RouteObject[] = [
    { path: '', element: <DashiList />, loader: dashiListLoader},
    { path: 'new', element: <Dashi />, loader: dashiActions},
    { id: 'dashi', path: ':dashiSlug', element: <Dashi />, loader: dashiLoader},
  ];

export function DashiLayout() {
  return (
    <div id='dashi-layout'>
      <Outlet />
    </div>
  )
}

function DashiList() {
  const dashis = useLoaderData() as {id: string, name: string}[];
  return (
  <div id="dashi-list">
    <h2>Dashi List</h2>
    <Form method='post'>
      <button type="submit">New</button>
    </Form>
    <ul>
      {dashis.map(d => {return (<li key={d.id}><Link to={d.id}>{d.name}</Link></li>)})}
    </ul>
  </div>
  )
}

function Dashi() {
  const { dashiSlug } = useParams();
  const dashi = useLoaderData() as DashiDO;
  return (
    <div id='dashi'>
      <div>dashiSlug: {dashiSlug}</div>
      <div>dashiId: {dashi.dashiId}</div>
      <div>title: {dashi.content.title.toString()}</div>
      <div>owner: {dashi.owner.username}</div>
    </div>
    );

  // return (
  //   <div id='dashi'>
  //     dashiSlug: {id}
  //   </div>
  // );
}

async function getDashi(id: string): Promise<DashiDO> {

  return Promise.resolve({
      dashiId: id,
      title: 'Dashi Title',
      owner: { email: 'thekumar@gmail.com', userId: '1', username: 'thekumar'},
      userPermissions: [],
      orgPermissions: [],
      content: {
        title: new Text('Dashi Title'),
        sections: [
          {title: 'Section 1', widgets: [
            {title: 'Widget 1', type: 'text', content: 'Hello world! 🌏', size: [1,1]},
            {title: 'Widget 2', type: 'text', content: 'Hello world! 🌏', size: [2,2]}
          ]},
          {title: 'Section 2', widgets: [
            {title: 'Widget 1', type: 'text', content: 'Hello world! 🌏', size: [3,3]},
            {title: 'Widget 2', type: 'text', content: 'Hello world! 🌏', size: [2,2]}
          ]}
        ] as DashiSectionDO[]
      }
    });
}