import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider as StoreProvider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
//import withScrolling from 'react-dnd-scrolling';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
//const ScrollZone = withScrolling('div');


declare module 'react-transition-group';

Sentry.init({
  dsn: "https://e0de60e5d0bb41528f3433cfd23c7c85@o4505360378429440.ingest.sentry.io/4505360380329984",
  integrations: [
    // new Sentry.BrowserTracing({
    //   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ["localhost", "https://datayaki.com", "https://staging.datayaki.com"],
    // }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const theme = createTheme();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <ThemeProvider theme={theme}>
    <StoreProvider store={store}>
    <DndProvider backend={HTML5Backend}>
    <App />
    </DndProvider>
    </StoreProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
