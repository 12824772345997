import { Datasource } from "../../dal/do/Datasource";
import { DatasourceDO, DataframeDO, WidgetDO } from "datayaki-api";
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import { updateDataContext, suggestQuestions, askSlice } from './ask-slice';

export interface DashiState {
  dashiId: string | null,
  dashiSlug: string | null,
  dashiName: string | null,
  status: 'idle' | 'pending',
  syncStatus: 'idle' | 'saving' | 'error',
  lastEdit: Date | null,
  lastSync: Date | null,
  datasources: DatasourceDO[],
  datasets: {[key: string]: DataframeDO},
  suggestedWidgets: WidgetDO[],
  widgets: WidgetDO[]
}

const initialState: DashiState = {
  dashiId: null,
  dashiSlug: null,
  dashiName: null,
  status: 'idle',
  syncStatus: 'idle',
  lastEdit: null,
  lastSync: null,
  datasources: [],
  datasets: {},
  suggestedWidgets: [],
  widgets: []
}

export type LoadDashiByIdRequest = {
  id: string
}

export type LoadDashiBySlugRequest = {
  username: string
  dashiSlug: string
}

export type AddDatasourcesRequest = {
  datasources: Datasource[]
}

export type RemoveDatasourceRequest = {
  id: string
}

export type UpdateDatasourcesRequest = {
  datasources: DatasourceDO[],
  datasets: {[key: string]: DataframeDO}
}

export type AddWidgetRequest = {
  widget: WidgetDO
}

export type RemoveWidgetRequest = {
  id: string
}

export const addDatasources = createAsyncThunk<
  boolean,
  AddDatasourcesRequest,
  { rejectValue: string }>(
    'dashi/addDatasource',
    async (req: AddDatasourcesRequest, thunkApi) => {
      let datasources = selectDashiDatasources(thunkApi.getState() as RootState);
      datasources = [...datasources, ...(await Promise.all(req.datasources.map(async (ds) => ds.toDatasourceDO())))];
      const datasets: {[key: string]: DataframeDO} = {};
      Object.assign(datasets, selectDashiDatasets(thunkApi.getState() as RootState));
      await Promise.all(req.datasources.map(async (ds) => datasets[ds.id] = await ds.getDataframe()))
      store.dispatch(askSlice.actions.resetSuggestions());
      store.dispatch(dashiSlice.actions.updateDatasources({datasources, datasets}));
      await store.dispatch(updateDataContext(datasources));
      store.dispatch(suggestQuestions());
      return true;
    }
  );

export const removeDatasource = createAsyncThunk<
  boolean,
  RemoveDatasourceRequest,
  { rejectValue: string }>(
    'dashi/removeDatasource',
    async (req: RemoveDatasourceRequest, thunkApi) => {
      let datasources = selectDashiDatasources(thunkApi.getState() as RootState);
      datasources = datasources.filter(d => d.id !== req.id);
      const datasets: {[key: string]: DataframeDO} = {};
      Object.assign(datasets, selectDashiDatasets(thunkApi.getState() as RootState));
      delete datasets[req.id];
      store.dispatch(askSlice.actions.resetSuggestions());
      store.dispatch(dashiSlice.actions.updateDatasources({datasources, datasets}));
      await store.dispatch(updateDataContext(datasources));
      store.dispatch(suggestQuestions());
      return true;
    }
  )


export const dashiSlice = createSlice({
  name: 'dashi',
  initialState,
  reducers: {
    loadDashiById: (state, action: PayloadAction<LoadDashiByIdRequest>) => {
      state.dashiId = action.payload.id;
      state.dashiSlug = 'demo';
      state.dashiName = 'Demo Dashi';
      state.datasources = [];
      state.datasets = {};
      state.widgets = [];
      state.lastEdit = null;
      state.lastSync = new Date();
    },
    loadDashiBySlug: (state, action: PayloadAction<LoadDashiBySlugRequest>) => {
      state.dashiId = crypto.randomUUID();
      state.dashiSlug = 'demo';
      state.dashiName = 'Demo Dashi';
      state.datasources = [];
      state.datasets = {};
      state.widgets = [];
      state.lastEdit = new Date();
      state.lastSync = new Date();
    },
    updateDatasources: (state, action: PayloadAction<UpdateDatasourcesRequest>) => {
      state.datasources = action.payload.datasources;
      state.datasets = action.payload.datasets;
    },
    addWidget: (state, action: PayloadAction<AddWidgetRequest>) => {
      state.widgets = [...state.widgets, action.payload.widget];
    },
    removeWidget: (state, action: PayloadAction<RemoveWidgetRequest>) => {
      state.widgets = state.widgets.filter(w => w.id !== action.payload.id);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addDatasources.pending, () => {});
    builder.addCase(addDatasources.fulfilled, () => {});
    builder.addCase(addDatasources.rejected, () => {});
    builder.addCase(removeDatasource.pending, () => {});
    builder.addCase(removeDatasource.fulfilled, () => {});
    builder.addCase(removeDatasource.rejected, () => {});
  }
})

export const selectDashiDatasources = (state: RootState) => state.dashi.datasources;
export const selectDashiDatasets = (state: RootState) => state.dashi.datasets;
export const selectDashiId = (state:RootState) => state.dashi.dashiId;
export const selectDashiName = (state:RootState) => state.dashi.dashiName;
export const selectDashiState = (state:RootState) => state.dashi;
