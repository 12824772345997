import { ChartType } from '../../../app/controllers/ask-slice';
import './renderers.css';
import * as React from 'react';

import { Chart as ChartJS, ArcElement } from 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

ChartJS.register(ArcElement);
ChartJS.defaults.responsive = true;

const hues: number[] = Array(40).fill(0).map((_, i) => ((75*i) + 45) % 360);

export function DataframeRenderer(props: { data: any, isRow: boolean }) {
  if (Array.isArray(props.data)) {
    if (props.data[0] instanceof Object) {
      const cols = Object.keys(props.data[0]).map(k => { return {field: k, headerName: k}});
      const rows = props.data.map((r,i) => {return {id:i, ...r }});
      console.log(rows);
      return (<Box sx={{width: '100%', height: '300'}}>
        <DataGrid rows={rows} columns={cols} initialState={{
          pagination: {
            paginationModel: {
              pageSize: 4,
            },
          },
        }} pageSizeOptions={[4]} disableRowSelectionOnClick slots={{toolbar: GridToolbar}}/>
      </Box>)
    }
     else {
      return <div className={props.isRow?'dataframe-row':'dataframe-col'}>{props.data.map(v => <DataframeRenderer isRow={!props.isRow} data={v}/>)}</div>
     }
  } else if (props.data instanceof Object) {
    return <div className={props.isRow?'dataframe-row':'dataframe-col'}>{Object.keys(props.data).map(v => <div className='dataframe-row'><div className='dataframe-key'>{v}</div><DataframeRenderer isRow={false} data={props.data[v]}/></div>)}</div>
  } else {
    return <div className='dataframe-value'>{props.data}</div>
  }
}

export function ChartRenderer(props: { type: ChartType, data: any, options?: any }) {
  let data = props.data;
  if (data.chartConfig) {
    data = data.chartConfig;
  }
  
  const labels = data.labels ? data.labels : undefined
  if (!(data.datasets && Array.isArray(data.datasets) && data.datasets[0].data)) {
    if (Array.isArray(data) && data[0].data) {
      data = {datasets: data, labels};
    } else if (data.data) {
      data = {datasets: [data], labels};
    } else {
      data = {datasets: [{data}], labels};
    }
  }

  let options = props.options ?? {};

  options['legend'] = { display: false };

  if (Array.isArray(data.datasets) && Array.isArray(data.datasets[0].data)) {
    for (let i = 0; i < data.datasets.length; i++) {
      data.datasets[i]['backgroundColor'] = (data.datasets[i].data as any[]).map((_, h) => 'hsla('+hues[(i+h)%40]+',100%,50%,0.75)');
      data.datasets[i]['borderColor'] = (data.datasets[i].data as any[]).map((_, h) => 'hsla('+hues[(i+h)%40]+',100%,40%,1)');
    }
  }
  if (data.datasets.length > 1) {
    const isSingleDataDatasets = data.datasets.reduce((output: boolean, ds: {data: any[]}) => output && ds.data.length <= 1, true);
    if (isSingleDataDatasets) {
      data = data.datasets.reduce(
        (output: any, ds: any) => {
          return {
            datasets: [{
              data: [...output.datasets[0].data, ds.data[0]],
              backgroundColors: [...output.datasets[0].backgroundColors,
              ds.backgroundColor],
              borderColors: [...output.datasets[0].borderColors,ds.borderColor]
            }],
            labels:[...output.labels,ds.label]
          }
        },
        {datasets: [{data:[], backgroundColors:[], borderColors:[]}], labels:[]});
    }
  }
  return (
      <Chart type={props.type} options={options} data={data} />
  )
}
