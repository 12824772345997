import { Outlet, RouteObject, useParams } from "react-router-dom";
import * as React from 'react';

export const datasourceRoutes: RouteObject[] = [
    { path: '', element: <DatasourceList/>},
    { path: 'new', element: <Datasource />},
    { path: ':datasourceSlug', element: <Datasource />},
  ];

export function DatasourceLayout() {
  return (
    <div id='datasource-layout'><Outlet/></div>
  )
}

function DatasourceList() {
  return (
    <div id='datasource-list'>Dataset List</div>
  )
} 
function Datasource() {
  const { datasourceSlug } = useParams();
  return (<div>Dataset: {datasourceSlug}</div>);
}
