import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './controllers/auth-slice';
import { askSlice } from './controllers/ask-slice';
import { uiSlice } from './controllers/ui-slice';
import { dashiSlice } from './controllers/dashi-slice';
import { cxSlice } from './controllers/cx-slice';
import { datayakiApi } from './api/api';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

export const store = configureStore({
  reducer: {
    [datayakiApi.reducerPath]: datayakiApi.reducer,
    auth: authSlice.reducer,
    ask: askSlice.reducer,
    cx: cxSlice.reducer,
    dashi: dashiSlice.reducer,
    ui: uiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(datayakiApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
