import { ErrorOutline } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import React from "react";

export class ErrorBoundary extends React.Component<{children?: React.ReactNode, fallback?: (error?: Error) => React.ReactNode, silent?: boolean},{hasError: boolean, error?: Error}> {
  constructor(props: {children?: React.ReactNode, fallback?: (error?: Error) => React.ReactNode, silent?: boolean}) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    if (!this.props.silent)
      enqueueSnackbar("🤖 Encountered an error!", { variant: 'error', preventDuplicate: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback?.(this.state.error) ?? <ErrorOutline sx={{color: "red", fontSize: "xx-large"}}/>;
    }

    return this.props.children;
  }
}