import posthog from 'posthog-js';
export var LoggedOutEventType;
(function (LoggedOutEventType) {
    LoggedOutEventType["SIGNUP_BUTTON"] = "SIGNUP_BUTTON";
})(LoggedOutEventType || (LoggedOutEventType = {}));
export var LoggedInEventType;
(function (LoggedInEventType) {
    LoggedInEventType["LOGIN"] = "LOGIN";
    LoggedInEventType["LOGOUT"] = "LOGOUT";
    LoggedInEventType["DASHI_ADD_DATASOURCES"] = "DASHI_ADD_DATASOURCES";
    LoggedInEventType["DASHI_REMOVE_DATASOURCES"] = "DASHI_REMOVE_DATASOURCES";
    LoggedInEventType["DASHI_DROP_FILES"] = "DASHI_DROP_FILES";
    LoggedInEventType["DASHI_ASK_QUESTION"] = "DASHI_ASK_QUESTION";
    LoggedInEventType["DASHI_ADD_WIDGET"] = "DASHI_ADD_WIDGET";
    LoggedInEventType["DASHI_REMOVE_WIDGET"] = "DASHI_REMOVE_WIDGET";
    LoggedInEventType["DASHI_VIEW_SUGGESTIONS"] = "DASHI_VIEW_SUGGESTIONS";
    LoggedInEventType["DASHI_HIDE_SUGGESTIONS"] = "DASHI_HIDE_SUGGESTIONS";
    LoggedInEventType["DASHI_ADD_SUGGESTION"] = "DASHI_ADD_SUGGESTION";
    LoggedInEventType["DASHI_REMOVE_SUGGESTION"] = "DASHI_REMOVE_SUGGESTION";
    LoggedInEventType["DASHI_CREATE"] = "DASHI_CREATE";
    LoggedInEventType["DASHI_DELETE"] = "DASHI_DELETE";
    LoggedInEventType["DATASET_CREATE"] = "DATASET_CREATE";
    LoggedInEventType["DATASET_DELETE"] = "DATASET_DELETE";
})(LoggedInEventType || (LoggedInEventType = {}));
const x = { id: 'xyz' };
class PosthogLogger {
    constructor() {
        posthog.init('phc_MZSdMXApTMgiX3M4qDHIYmZW2xV4Hcv9qQ3bFQgPrhR', { api_host: 'https://app.posthog.com' });
    }
    logEvent(type, props) {
        posthog.capture(type, props);
    }
}
export const eventLogger = new PosthogLogger();
