import { DataframeDO, DatasourceDO } from "datayaki-api";
import Papa from 'papaparse';
import { Datasource } from "../dal/do/Datasource";
import * as XLSX from 'xlsx';

export class XlsxUtils {
  static async getDatasources(idPrefix: string, dashiId: string, datasourceName: string, file: File): Promise<Datasource[]> {
    const workbook = XLSX.read(await file.arrayBuffer(), {raw: true});
    const datasources = workbook.SheetNames.filter(name => {console.log(workbook.Sheets[name]['!type']); return workbook.Sheets[name]['!type'] !== 'chart';}).map(name => {
      const id = idPrefix + '-' + name;
      return new XlsDatasource(id, dashiId, id, XLSX.utils.sheet_to_csv(workbook.Sheets[name]), file);
    });

    return datasources;
  }
}

export class XlsDatasource implements Datasource {
  readonly id: string;
  dashiId: string;
  datasourceName: string;
  readonly type = 'CSV';
  srcInfo: any;
  csvString: string;
  isParsed = false;
  hasHeaders: boolean;
  dataframe: DataframeDO;
  dataframeForContext: DataframeDO;

  constructor(id: string, dashiId: string, datasourceName: string, csvString: string, file: File) {
    this.id = id;
    this.dashiId = dashiId;
    this.datasourceName = datasourceName;
    this.csvString = csvString;
    this.hasHeaders = true;
    this.srcInfo = file.webkitRelativePath+file.name;
    this.dataframe = { attributes: [], data: [] };
    this.dataframeForContext = { attributes: [], data: [] };
    console.log(csvString);
  }

  parse(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        Papa.parse(this.csvString, {
          header: false,
          dynamicTyping: true,
          complete: (result, file) => {
            try {
              if ((this.hasHeaders && result.data.length < 2) || result.data.length < 1) {
                console.warn('CSV has no data!');
                resolve(true);
                return;
              }
              if (this.hasHeaders) {
                const data: any[] = result.data.splice(1);
                const attributes: string[] = [];
                (result.data[0] as any[]).map((h, i) => attributes.push(h as string));
                this.dataframe = { data, attributes };
              } else {
                this.dataframe = {data: result.data as any[], attributes: [this.id]};
              }
              this.dataframeForContext = {
                attributes: this.dataframe.attributes.slice(),
                data: this.dataframe.data.slice(0,3)
              };
              resolve(true);
            } catch (e) {
              console.error(e);
              reject(e);
            }
          }
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  async getDataframe(): Promise<DataframeDO> {
    if (!this.isParsed) {
      this.isParsed = await this.parse();
    }
    return this.dataframe;
  }

  async getDataframeForContext(): Promise<DataframeDO> {
    if (!this.isParsed) {
      this.isParsed = await this.parse();
    }
    return this.dataframeForContext;
  }

  toDataframeForContext(data: any[], attributes: string[]) {
    let sampleData = data.slice(0,3);
    // sampleData.map(row => deidentifyData(row))
    return {
      attributes,
      data: sampleData
    };
  }

  async toDatasourceDO(): Promise<DatasourceDO> {
    if (!this.isParsed) {
      this.isParsed = await this.parse();
    }

    return {
      id: this.id,
      dashiId: this.dashiId,
      dataframe: this.dataframe,
      dataframeForContext: this.dataframeForContext,
      datasourceName: this.datasourceName,
      srcInfo: this.srcInfo,
      type: this.type
    }
  }
}
